
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import 'echarts-liquidfill'
import { ToiletDetail } from '@/types/toilet.d'
import { drawPolygon } from '@/utils/formatData'

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/toilet/normal.png')
  private onlineImgCursor = require('@/assets/icon/toilet/normal-big.png')
  private offImg = require('@/assets/icon/toilet/offline.png')
  private offImgCursor = require('@/assets/icon/toilet/offline-big.png')

  private deviceList: Array<ToiletDetail> = []
  private drawerData: ToiletDetail | null = null
  private detail: ToiletDetail | null = null
  private manChart: any = null
  private womenChart: any = null
  // sex: 1 男； 2 女 （wSex：空气数据； cSex：耗材）
  private wSex: '1' | '2' = '1'
  private cSex: '1' | '2' = '1'

  get projectId () {
    return this.$route.params.projectId as string
  }

  get wData () {
    const detail = this.detail as ToiletDetail
    return this.wSex === '1' ? detail.manToiletAir || {} : detail.womanToiletAir || {}
  }

  get cData () {
    const detail = this.detail as ToiletDetail
    return this.cSex === '1' ? detail.manConsumableStatistics || {} : detail.womanConsumableStatistics || {}
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    this.manChart && this.manChart.dispose()
    this.womenChart && this.womenChart.dispose()
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get(this.$apis.toilet.selectSumProject, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.smartToiletList || []
      const data = [{
        name: '男厕',
        value: res.manDayTotalInSum || 0,
        label: {
          normal: {
            formatter: '合计 ' + res.dayTotalInSum || '0',
            position: 'center',
            show: true,
            textStyle: {
              fontSize: '14',
              fontWeight: 'normal',
              color: '#333333'
            }
          }
        }
      }, {
        name: '女厕',
        value: res.womanDayTotalInSum || 0
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  getDetail () {
    this.$axios.get<ToiletDetail>(this.$apis.toilet.selectSmartToiletDetail, {
      toiletId: (this.drawerData as ToiletDetail).toiletId
    }).then(res => {
      this.detail = res
      this.drawDialogChart('1', Number(this.detail.manCubicleUsingNum), Number(this.detail.manCubicleNum))
      this.drawDialogChart('2', Number(this.detail.womanCubicleUsingNum), Number(this.detail.womanCubicleNum))
    })
  }

  // 男厕女厕图
  drawEchart (data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs.echarts as any)
    const option = {
      title: {
        text: '当日客流',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#53C8D1', '#FF6AD4'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (name: string) => {
          const ind = data.findIndex(dd => dd.name === name)
          return ind >= 0 ? `${name}  ${data[ind].value}` : ''
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['40%', '60%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, runStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? this.onlineImg : this.offImg)
    } else {
      return deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
    }
  }

  // 地图打点
  drawMarker (list: Array<ToiletDetail>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: ToiletDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.runStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) {
        this.drawerClose()
      }
      this.drawerData = e.target.detail
      this.getDetail()
      // 点击切换大图标
      const iconClick = this.getIcon(e.target.detail.deviceStatus, e.target.detail.runStatus, true)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      this.detail = null
      this.wSex = '1'
      this.cSex = '1'
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
        const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.runStatus, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }

  // 水波纹
  drawDialogChart (sex: '1' | '2', num: number, total: number) {
    this.$nextTick(() => {
      let chartName = 'manChart'
      let name = '男厕'
      let color1 = '#1890FF'
      let color2 = '#91D5FF'
      let borderColor = '#CCD9F8'
      let bgColor = '#F0F8FF'
      let chart = this.manChart
      if (sex === '2') {
        chartName = 'womenChart'
        name = '女厕'
        color1 = '#FF9896'
        color2 = '#FF5BEA'
        borderColor = '#CDACAC'
        bgColor = '#FFFAFA'
        chart = this.womenChart
      }

      chart = echarts.init(this.$refs[chartName] as any)
      const option = {
        title: {
          text: '{name|' + name + '}\n{value|' + num + '/' + total + '}',
          textStyle: {
            rich: {
              fontSize: 14,
              name: {
                fontFamily: 'PingFangSC, PingFang SC',
                lineHeight: 20,
                color: '#333333'
              },
              value: {
                fontFamily: 'HelveticaNeue',
                color: '#000000',
                textShadowColor: 'rgba(0, 0, 0, 0.5)',
                textShadowBlur: 2,
                textShadowOffsetX: 0,
                textShadowOffsetY: 0
              }
            }
          },
          x: 'center',
          y: '12%'
        },
        backgroundColor: 'transparent',
        series: [{
          type: 'liquidFill',
          radius: '80%',
          center: ['50%', '50%'],
          color: [
            {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: color1
                },
                {
                  offset: 0,
                  color: color2
                }
              ],
              globalCoord: false
            }
          ],
          backgroundStyle: {
            color: bgColor
          },
          // emphasis: {
          //   itemStyle: {
          //     dataColor
          //   }
          // },
          itemStyle: {
            shadowBlur: 0
          },
          outline: {
            borderDistance: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: borderColor
            }
          },
          label: {
            formatter: ''
          },
          shadowBlur: 0,
          shadowColor: 'transparent',
          waveAnimation: false, // 禁止左右波动
          animationDuration: 0, // 初始动画时长
          animationDurationUpdate: 0,
          data: [num / total]
        }]
      }
      chart.clear()
      chart.setOption(option)
    })
  }

  calcWidth (num: string, total: string) {
    const t = Number(total)
    const n = Number(num)
    return t ? (n / t * 50 + 25) + '%' : '50%'
  }
}
